
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isRequired } from '@/utils/validation'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { dateDotNotationToDashWithForm, dateDashNotationToDotWithForm } from '@/utils/convertDate'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { handleError } from '@/utils/handleError'

import { useGetDemandBasic } from '@/api/demand'
import { useGetAccountsBasic } from '@/api/account'
import { useGetProfilesBasic } from '@/api/profile'
import { useCreateTendering, useUpdateTendering } from '@/api/tendering'
import { useGetEnumItemsBasic } from '@/api/enumItem'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { TenderingOutput, TenderingInput } from '@/api/types/tendering'
import { PersonId } from '@/api/types/person'
import { isOfType } from '@/utils/types/isOfType'
import { PROFILE_TYPE } from '@/api/types/profile'
import { useGetPositionsBasic } from '@/api/position'
import { SOURCING_TYPE } from '@/views/planning/views/workforcePlanning/types'

export default defineComponent({
  name: 'AddEditTenderingDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    tenderingToEdit: {
      type: Object as PropType<TenderingOutput>,
      default: null,
    },
    personId: {
      type: Number as PropType<PersonId>,
      default: null,
    },
  },

  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.tenderingToEdit))

    const { exec: getDemandsBasic, data: demands, isLoading: isLoadingDemandsBasic } = useGetDemandBasic()
    getDemandsBasic()

    const { exec: getProfilesBasic, data: profiles, isLoading: isLoadingProfilesBasic } = useGetProfilesBasic()
    getProfilesBasic({ params: { type: PROFILE_TYPE.TENDERING } })

    const { exec: getAccountsBasic, data: accounts, isLoading: isLoadingAccountsBasic } = useGetAccountsBasic()
    getAccountsBasic()

    const { exec: getPositionsBasic, data: positions, isLoading: isLoadingPositionsBasic } = useGetPositionsBasic()
    getPositionsBasic({ params: { positionStatuses: 'ACTIVE', approved: true, isAssignableToTendering: true } })
    const mergedPositions = computed(() => {
      if (props.tenderingToEdit) {
        return [props.tenderingToEdit.position, ...(positions.value ?? [])]
      } else {
        return positions.value ?? []
      }
    })

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()
    getEnumItemsBasic({ params: { enumItemAssignment: 'TENDERING_STATUS' } })

    const FORM_FIELDS: FormField[] = [
      {
        value: 'demand',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => demands.value ?? []),
        isLoading: computed(() => isLoadingDemandsBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.tenderings.form.demand') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'position',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => mergedPositions.value ?? []),
        isLoading: computed(() => isLoadingPositionsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'profile',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => profiles.value ?? []),
        isLoading: computed(() => isLoadingProfilesBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.tenderings.form.profile') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'account',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => accounts.value ?? []),
        isLoading: computed(() => isLoadingAccountsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'totalCount',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.tenderings.form.totalCount') as string)],
      },
      {
        value: 'deadline',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'status',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => enumItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('contractData.tenderings.form.status') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'sourcing',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => SOURCING_TYPE ?? []),
        isRequired: true,
        rules: [],
        dropdownTextProp: 'name',
      },
    ]

    const form = ref<TenderingInput | TenderingOutput>(
      isEditMode.value ? cloneDeep(props.tenderingToEdit) : ({} as TenderingInput)
    )

    const { createTendering, isLoading: isLoadingCreateTendering } = useCreateTendering()

    async function onAdd(): Promise<void> {
      if (!isOfType<TenderingOutput>(form.value, 'id')) {
        try {
          let updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, form.value)

          updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

          await createTendering(updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateTendering, isLoading: isLoadingUpdateTendering } = useUpdateTendering()

    async function onEdit(): Promise<void> {
      if (isOfType<TenderingOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<TenderingOutput>(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        try {
          await updateTendering(updatedForm.id, updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditTendering = computed(() => isLoadingCreateTendering.value || isLoadingUpdateTendering.value)

    watch(
      () => props.tenderingToEdit,
      () => {
        if (props.tenderingToEdit) {
          form.value = props.tenderingToEdit

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    function close(): void {
      emit('reload-tenderings')

      emit('close')
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditTendering,
      },
      listeners: {
        onAdd,
        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
