import { BasicEntity } from '@/api/types/misc'
import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'

import {
  Account,
  AccountId,
  NewAccount,
  UseGetAccounts,
  UseGetAccountsBasic,
  UseCreateAccount,
  UseDeleteAccount,
  UseUpdateAccount,
} from './types/account'

const VERSION = 'v1'
const RESOURCE = 'accounts'

function useGetAccounts(): UseGetAccounts {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetAccounts['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetAccountsBasic(): UseGetAccountsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateAccount(): UseCreateAccount {
  const axios = useAxios<PromiseType<ReturnType<UseCreateAccount['createAccount']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createAccount(data: NewAccount): Promise<Account> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createAccount,
  }
}

function useUpdateAccount(): UseUpdateAccount {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateAccount['updateAccount']>>>({ method: 'PUT' })

  function updateAccount(id: AccountId, data: Account): Promise<Account> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateAccount,
  }
}

function useDeleteAccount(): UseDeleteAccount {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteAccount['deleteAccount']>>>({ method: 'DELETE' })

  function deleteAccount(id: AccountId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteAccount,
  }
}

export { useGetAccounts, useGetAccountsBasic, useCreateAccount, useUpdateAccount, useDeleteAccount }
